<template>
    <div class="auth_layout pt-8">
        <header class="header">
            <div class="container">
                <div class="header_inner">
                    <div class="auth_logo">
                        <router-link :to="{name: 'login'}">
                            <!-- <LogoBlock /> -->
                            <img 
                                class="logo"
                                src="@/assets/images/logo_white_text.svg" 
                                alt="logo">
                        </router-link>
                    </div>
                    <div class="register_button">
                        <a-button 
                            size="large"
                            @click="openRegistration"
                            type="primary">
                            Зарегистрироваться
                        </a-button>
                    </div>
                </div>
            </div>
        </header>
        <main class="main">
            <div class="container flex-grow flex flex-col">
                <transition name="translate" mode="out-in">
                    <router-view v-if="authConfig" />
                </transition>
            </div>
        </main>
        <RegisterDrawer />
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import LogoBlock from './components/LogoBlock.vue'
import eventBus from '@/utils/eventBus'
import RegisterDrawer from '@/views/Authorization/RegisterDrawer'
export default {
    components: {
        RegisterDrawer
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        register() {
            return this.$route?.name === 'registration' ? true : false
        },
        authConfig() {
            return this.$store.state.user.authConfig
        },
        locales() {
            return this.$i18n.availableLocales.filter(f => f !== this.$i18n.locale)
        }
    },
    data() {
        return {
            loading: false,
            formInfo: null,
            show: false
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        openRegistration() {
            eventBus.$emit('open_registration')
        },
        changeLocale(locale) {
            this.$i18n.locale = locale
            this.$router.push({params: { lang: locale }})
        },
        async getInfo() {
            try {
                this.loading = true
                await this.$store.dispatch('user/getAuthConfig')
                if(this.authConfig.reCAPTCHASiteKey) {
                    this.capGenerate()
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        capGenerate() {
            const script = document.createElement('script')
            script.async = true
            script.src = `https://www.google.com/recaptcha/api.js?trustedtypes=true&render=${this.authConfig.reCAPTCHASiteKey}`
            script.onload = () => {}
            script.onerror = () => {
                console.log('%c Error occurred while loading script', 'color: #bf1432')
            }
            document.body.appendChild(script)
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    height: 76px;
}
.container {
    $padding-x: 30px;
    max-width: calc(1600px - $padding-x);
    padding: 0 $padding-x;
    margin: 0 auto;
    height: 100%;
    @media (max-width: 680px) {
        padding: 0 16px;
    }
}
.header {
    margin-bottom: 2rem;
}
.header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    .ant-btn::v-deep {
        height: 50px;
        padding: 0 50px;
    }
    @media (max-width: 600px) {
        height: auto;
        flex-direction: column;
        justify-content: center;
        // mb
        .auth_logo {
            margin-bottom: 3rem;
        }
        .register_button {
            width: 100%;
            .ant-btn::v-deep {
                width: 100%;
            }
        }
    }
}

::v-deep{
    .lang_switch__popup{
        max-width: 150px;
    }
}
.auth_route{
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    visibility: hidden;
    opacity: 0;
    &.show{
        opacity: 1;
        visibility: visible;
    }
}
.translate-enter-active,
  .translate-leave-active {
    transition: all 0.5s ease;
  }
  .translate-enter-from,
  .translate-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
.auth_layout{
    font-family: 'Rubik', sans-serif;

    display: flex;
    flex-direction: column;

    height: 100vh;
    width: 100vw;
    padding-bottom: 200px;

    overflow-y: auto;;
    
    background-image: url("~@/assets/images/auth_bg.png");
    background-size: cover;


    // min-width: 980px;
    // position: relative;
    // &::before{
    //     content: "";
    //     position: absolute;
    //     top: 0px;
    //     bottom: 0px;
    //     right: 0px;
    //     margin-top: -28%;
    //     margin-bottom: -15%;
    //     margin-right: -13%;
    //     width: 57%;
    //     --tw-rotate: -2deg;
    //     --tw-translate-x: 0;
    //     --tw-translate-y: 0;
    //     --tw-skew-x: 0;
    //     --tw-skew-y: 0;
    //     --tw-scale-x: 1;
    //     --tw-scale-y: 1;
    //     transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    //     border-radius: 100%;
    //     --color-primary: 29 101 192;
    //     background-color: rgb(var(--color-primary) / 0.2);
    // }
    // &::after{
    //     content: "";
    //     position: absolute;
    //     top: 0px;
    //     bottom: 0px;
    //     right: 0px;
    //     margin-top: -20%;
    //     margin-bottom: -12%;
    //     margin-right: -13%;
    //     width: 57%;
    //     --tw-rotate: -4deg;
    //     --tw-translate-x: 0;
    //     --tw-translate-y: 0;
    //     --tw-skew-x: 0;
    //     --tw-skew-y: 0;
    //     --tw-scale-x: 1;
    //     --tw-scale-y: 1;
    //     --color-primary: 29 101 192;
    //     transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    //     border-radius: 100%;
    //     --tw-bg-opacity: 1;
    //     background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
    // }
    // .auth_page{
    //     display: flex;
    //     height: 100%;
    //     min-height: 620px;
    // }
}
.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
</style>

<style lang="scss">
.grecaptcha-badge{
    // top: 10px;
    bottom: 10px;
    // bottom: initial!important;
}
</style>